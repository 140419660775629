import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

export default function ScreenerFilteredResult({filter}) {
    const [results, setResults] = useState([]);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    async function fetchResults() {
          const response = await fetch(`${process.env.REACT_APP_SCREENER_URL.replace("{filter}", filter)}`);
          setResults(await response.json())
    }

    useEffect(() => {
        fetchResults();
    }, []);


    if(results.size === 0){
        return (<p> - </p>);
    }

    return (
        <>
        <Table striped bordered hover variant="dark" responsive="sm" size="sm" className="text-center" style={{width: '94%', marginLeft: '3%'}}>
          <thead>
            <tr>
              <th>Ticker</th>
              <th>Name</th>
              <th>Sector</th>
              <th>Industry</th>
              <th>Country</th>
            </tr>
          </thead>
          <tbody>
            {results.map(({ ticker, name, sector, industry, country}, i) => (
                <tr key={filter+"_"+ticker+"_"+i}>
                    <td className="text-center" style={{width: '10%'}}>
                       <a href={'/?search='+ticker} target="_blank" rel="noreferrer"
                                               title={name} style={{ textDecoration: 'none'}}>{ticker}</a>
                    </td>
                    <td style={{width: '30%'}}>
                       <small>{name}</small>
                    </td>
                    <td style={{width: '20%'}}>
                        <small>{sector}</small>
                    </td>
                    <td>
                        <small>{industry}</small>
                    </td>
                    <td className="text-center" style={{width: '10%'}}>
                        <small>{country}</small>
                    </td>
                </tr>
            ))}
          </tbody>
       </Table>
        </>
    );
}